import Vue from 'vue'
import Config from '../../Config'
import {downloadFile, setDictValue, build_resource_list_params} from '@/common'
import {print_human_readable_size} from '@/filters'
import {field_tag, field_created_at, field_actions} from './fields'

const state = {
  labelList: [],
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  list: [],
  filteredList: [],
  _lookup: {},
  fields: [
    {
      "key": "name",
      "sortable": true
    },
    field_tag,
    {
      "key": "size",
      "formatter": print_human_readable_size,
      "sortable": true
    },
    field_created_at,
    field_actions,
  ],
  filters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => true,
    isDownloadable: (entry) => true
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getBundle: (state) => (bundleIdentifier) =>
    state.list,
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields,
  getLabel: (state) => (groupIdentifier) =>
    state.labelList[groupIdentifier],
  getPagination: (state) => () =>
    state.pagination,
}

const actions = {
  loadLabel({dispatch, commit}, {label, load}) {
    let _params = "?labels=" + encodeURIComponent(label)
    if (load)
      _params += "&expand=true"
    return Vue.http.get(Config.API_FILES_URL + _params).then((response) => {
      let files = response.data
      commit("setLabel", {label, files})
    })
  },
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/files"
    else
      url = Config.API_FILES_URL
    return Vue.http.get(url, {params}).then((response) => {
      let files = response.data
      commit('setAll', files)
    })
  },
  loadBundle({dispatch, commit}, bundleIdentifier) {
    return Vue.http.get(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/files").then((response) => {
      let files = response.data
      commit('setAll', files)
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_FILES_URL + '/' + identifier).then((response) => {
      let file = response.data
      commit('set', {identifier, file})
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let file = state._lookup[identifier]
    return Vue.http.put(Config.API_BUNDLES_URL + '/' + file.bundle_identifier + '/files/' + identifier, data).then((response) => {
      dispatch('load', identifier).then(() => {
      })
    })
  },
  delete({dispatch, commit}, identifier) {
    let user = state._lookup[identifier]
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + user.bundle_identifier + '/files/' + user.identifier).then((response) => {
      commit('delete', identifier)
    })
  },
  filter({dispatch, commit}, search) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      commit('filter', search)
    }, 600);
  },
  export({dispatch, commit}, {bundleIdentifier, identifier}) {
    let file = state._lookup[identifier]
    return Vue.http.get(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/files/' + identifier + '/export', {responseType: 'blob'}).then((response) => {
      return downloadFile(response, sanitizeTag(file.tag))
    })
  },
  search({dispatch, commit}, {search, filters = {}}) {
    return dispatch('search/file', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
  async add({dispatch, commit, rootGetters}, {file, bundleIdentifier, data, onerror, onfinished}) {
    if (data.name === undefined || data.name === null) {
      data.name = file.name.replace(/\s/g, '_')
    }
    if (data.tag === undefined || data.tag === null) {
      data.tag = data.name.substr(0, 50)
    }
    data.path = file.name.replace(/\s/g, '_')

    let response = await Vue.http.post(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/files', data)
    let response_data = response.data
    dispatch("load", response_data.identifier)
    let url = response_data.files[0].url
    let workflow_id = response_data.workflow_id
    dispatch("uploads/add", {
      identifier: response.identifier,
      description: data.tag,
      data: file,
      url: url,
      onerror: async (message) => {
        await dispatch("workflows/load", workflow_id, {root:true})
        let workflow = rootGetters['workflows/get'](workflow_id)
        dispatch("workflows/cancel", workflow, {root:true})
        onerror(message)
      },
      onfinished: () => {
        dispatch("files/load", response_data.identifier, {root:true})
        onfinished()
      }
    }, {root:true})
    return response_data.identifier
  },
  download({dispatch, commit}, {bundleIdentifier, identifier}) {
    let file = state._lookup[identifier]
    return Vue.http.get(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/files/' + identifier, {params: {download: true}}).then((response) => {
      return downloadFile(response, file.name)
    })
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setLabel(state, {label, files}) {
    state._lookup = {}
    if (state.labelList[label] !== undefined) {
      state.labelList[label].splice(0, state.labelList[label].length)
    } else {
      state.labelList[label] = []
    }
    for (let file of files.data) {
      state.labelList[label].push(file)
      setDictValue(state._lookup, file.identifier, file)
    }
  },
  setAll(state, files) {
    state._lookup = {}
    state.pagination.page = files.page
    state.pagination.total_pages = files.total_pages
    state.pagination.total = files.total
    state.list.splice(0, state.list.length)

    for (let file of files.data)
      state.list.push(file)

    for (let file of files.data)
      state._lookup[file.identifier] = file
  },
  set(state, {identifier, file}) {
    if (state._lookup[file.identifier] === undefined)
      state.list.push(file)
    setDictValue(state._lookup, file.identifier, file)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






