<template>
  <div>
    <b-modal size="lg" ref="addFormModal" id="addHostSpecModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="HostSpec" name="identifier" v-model="identifier" disabled
                       required></InputText>
            <InputText prefix="HostSpec" name="topology identifier" v-model="topologyIdentifier" disabled required
                       :help="topology.tag"></InputText>

            <b-form-select v-model="hardwareType" :options="hardwareTypeOptions" class="mb-3" :disabled="edit"/>
            <div class="alert alert-warning" role="alert" v-if="!edit && (hardwareType !== 'EM_T')">
              You need to upload the image(s) afterward.
            </div>

            <InputCheckbox prefix="HostSpec" name="Headless" v-model="headless" v-if="hardwareType !== 'CO_T'"
                           @input="headless = $event"
                           help="Should be checked if the VM has no graphical interface."/>

            <InputText prefix="HostSpec" name="Vid" v-model="vid" v-if="hardwareType === 'EM_T'"
                       @input="vid = $event"
                       help="Machine virtual identifier"></InputText>

            <hr/>
            <h5>Agent</h5>
            <hr/>

            <b-form-select v-model="agent" :options="agentOptions[hardwareType]" class="mb-3"/>

            <InputText prefix="default-shell" name="default_shell" v-model="agentDefaultShell" v-if="agent === 'lade_vmware_agent' || agent === 'vmware_tools'"
                       help="Shell used to execute commands from agent (let empty for default one (powershell for windows and sh for linux)"></InputText>

            <InputText prefix="agent-tmp-dir" name="tmp_dir" v-model="agentTmpDir" v-if="agent === 'vmware_tools'"
                       help="Directory for buffering command output"></InputText>

            <div v-if="hardwareType !== 'EM_T'">

              <hr/>
              <h5>Hardware</h5>
              <hr/>
              <InputText prefix="HostSpec" name="RAM (MB)" v-model="ramMB" v-if="hardwareType !== 'EM_T'"
                         required></InputText>
              <InputText prefix="HostSpec" name="CPU cores" v-model="cpuCores" v-if="hardwareType === 'VM_T'"
                         required></InputText>

            </div>

            <hr/>
            <h5>System</h5>
            <hr/>
            <InputText prefix="HostSpec"
                       name="hostname"
                       placeholder="Hostname"
                       v-model="hostname"
                       help="It must be the real hostname of the host"
                       required></InputText>

            <InputText v-if="hardwareType === 'VM_T'" name="Operating System"
                       placeholder="OS Type"
                       v-model="os"
                       help="Used by the hypervisor to apply default configuration depending on the flavor of the host."
                       required></InputText>

            <InputText prefix="HostSpec" name="Operating System" v-model="os" v-else
                       placeholder="Operating System description"
                       help="Only for information purpose. (e.g. CentOS:7)"/>


            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Icon <i style="color:gray;"> - Optional </i></label>

              <Icon :icon="icon" square/>
              <div class="col-sm-7">

                <b-tabs content-class="mt-3" v-model="iconTab">
                  <b-tab title="Select existing" lazy>
                    <img style="width:32px; cursor:pointer;"
                         v-for="icon in icons"
                         :key="icon.identifier"
                         :src="icon.data"
                         :alt="icon.name"
                         :class="{ iconSelected: icon.tag === iconTag }"
                         :title="icon.name"
                         @click.prevent="selectIcon(icon)"/>
                  </b-tab>
                  <b-tab title="Upload" lazy>
                    <InputImage prefix="Category" hideLabel name="icon" @input="(file) => {icon_updated = file}"/>
                  </b-tab>
                </b-tabs>

                <small class="form-text text-muted"></small>
              </div>
            </div>

            <InputText prefix="HostSpec" name="username" v-model="username"
                       help="It must a valid user of the system"></InputText>
            <InputText prefix="HostSpec" name="password" v-model="password"
                       help="It must be the correct password of the above user"></InputText>

            <hr/>
            <h5>Network</h5>
            <hr/>

            <InputText prefix="HostSpec" name="Default gateway" placeholder="(ex: 192.168.1.1)"
                       v-model="defaultGateway"></InputText>

            <InputText prefix="HostSpec" name="Interface default type" placeholder="(ex: vmxnet3)"
                       v-model="backingNicType"></InputText>

            <DynamicForm component="NicSpecForm" ref="nicsForm" title="Network interfaces"
                         name="network interface" :resources="nics">
            </DynamicForm>

            <hr/>
            <h5>Deployment</h5>
            <hr/>

            <InputCheckbox prefix="HostSpec" name="Immutable" v-model="immutable"
                           @input="immutable = $event"
                           help="Should be checked if the host should not be edited after deployment."/>

            <InputText prefix="HostSpec" name="priority" v-model="priority"
                       help="Deployment order during the deployment of the whole topology (from lowest to greatest)."/>

            <TextAreaForm prefix="HostSpec"
                          name="hooks"
                          ref="hooks"
                          :rows="10"
                          v-model="hooks"
                          :json="true"
                          help="Scripts that can be executed at different moments during the lifecycle of a virtual machine"/>

            <div v-if="hardwareType !== 'EM_T'">

              <hr/>
              <h5> Advanced Virtualization Parameters</h5>
              <hr/>

              <InputCheckbox prefix="HostSpec" name="Hardware Virtualization"
                             v-if="hardwareType === 'VM_T'"
                             v-model="hardwareVirtualization" @input="hardwareVirtualization = $event"
                             help="Allow you to use nested virtualization."/>

              <InputCheckbox prefix="HostSpec" name="Lazy Network"
                             v-if="hardwareType === 'VM_T'"
                             v-model="lazyNetwork" @input="lazyNetwork = $event"
                             help="When disabled, 9 network cards will be pre-created at deployment. This feature can be used for machine that doesn't support hot-add or hot-removal of network interfaces."/>

              <InputText prefix="HostSpec" name="Number of interfaces (1 - 9)"
                         help="Number of interfaces that will be created. You cannot increase this number afterwards. Minimum is 1, maximum is 9."
                         v-model="maxInterfaceNumber"
                         pattern="^[1-9]$"
                         required
                         v-if="hardwareType === 'VM_T' && !lazyNetwork"
                         ></InputText>

              <InputCheckbox prefix="HostSpec" name="Preserve Mac"
                             v-if="hardwareType === 'VM_T'"
                             v-model="preserveMac" @input="preserveMac = $event"
                             help="When a network card is created, set the default behavior to preserve the MAC addresses on deploy / save. This feature can be used for virtual machine that doesn't support mac address change."/>

              <InputCheckbox prefix="HostSpec" name="NET_ADMIN Capability"
                             v-if="hardwareType === 'CO_T'"
                             v-model="preserveMac" @input="netAdminCapability = $event"
                             help="Grant the container the NET_ADMIN capability, which allows the user to update its network configuration. WARNING: The user will be able to re-enable network cards even if they are disabled in LADE Interface."/>

              <TextAreaForm v-if="hardwareType === 'CO_T'"
                            prefix="HostSpec"
                            name="environments"
                            ref="backing"
                            :rows="Object.keys(this.environments).length + 1"
                            v-model="environments"
                            :json="true"
                            help='Send custom environment variable in the container. Lots of docker images can be configured at deployment with environment vars.
                            Exemple: {"POSTGRES_DB": "data", "POSTGRES_USER": "user"}'/>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Firmware </label>
                <div class="col-sm-9">
                  <b-form-select v-model="firmware" :options="bootOptions" default="bios"></b-form-select>
                </div>
              </div>
            </div>
            <TextAreaForm prefix="HostSpec" name="attributes" ref="attributes" v-model="attributes" :rows="5"
                          :json="true"/>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>


<script>
import store from '../../store'
import InputText from '../form/InputText'
import TextAreaForm from '../form/TextAreaForm'
import DynamicForm from '../DynamicForm'
import {print_tag} from '@/filters'
import Icon from '../form/Icon'
import InputImage from '../form/InputImage'
import InputCheckbox from '../form/InputCheckbox'

export default {
  name: 'HostSpecsForm',
  components: {
    InputText, TextAreaForm, DynamicForm, Icon, InputImage, InputCheckbox
  },
  created() {
    this.$eventBus.$off('add-host-spec-show')
    this.$eventBus.$on('add-host-spec-show', (topologyIdentifier) => {
      this.loadModalResources().then(() => {
        this.reset('Add Host')
        this.hardwareType = "VM_T"
        this.topologyIdentifier = topologyIdentifier
        this.topology = store.getters['topologies/get'](this.topologyIdentifier)
        this.bundleIdentifier = this.topology.bundle_identifier
        if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
        if (this.$refs.hooks) this.$refs.hooks.$emit('reset')
        this.$refs.addFormModal.show()
      })
    })

    this.$eventBus.$off('edit-host-spec-show')
    this.$eventBus.$on('edit-host-spec-show', ({topologyIdentifier, identifier}) => {
      this.loadModalResources().then(() => {
        this.reset('Edit Host')
        this.edit = true
        this.topologyIdentifier = topologyIdentifier
        let spec = store.getters['topologies/getHostSpec'](this.topologyIdentifier, identifier)
        this.images = spec.images
        this.software = spec.software
        this.nics = spec.nics
        this.icon = spec.icon

        this.topology = store.getters['topologies/get'](this.topologyIdentifier)
        this.bundleIdentifier = this.topology.bundle_identifier
        this.identifier = identifier
        this.hostname = spec.hostname
        this.ramMB = spec.ram_mb
        this.cpuCores = spec.cpu_cores
        this.diskSizeMB = spec.disk_size_mb
        this.os = spec.os
        this.username = spec.username
        this.password = spec.password
        this.vid = spec.vid

        this.defaultGateway = spec.default_gateway
        this.nics = spec.nics
        this.hooks = spec.hooks
        this.immutable = spec.immutable
        this.priority = spec.priority
        this.backing = spec.backing
        this.hardwareVirtualization = this.backing.hardware_virtualization
        if (this.backing.lazy_network === undefined)
          this.lazyNetwork = true
        else
          this.lazyNetwork = this.backing.lazy_network
        if (this.backing.capabilities !== undefined && "NET_ADMIN" in this.backing.capabilities) {
          this.netAdminCapability = true
        }
        if (this.backing.environments !== undefined) {
          this.environments = this.backing.environments
        }
        this.firmware = this.backing.firmware || "bios"
        if (this.backing.nic_backing !== undefined && this.backing.nic_backing.type !== undefined) {
          this.backingNicType = this.backing.nic_backing.type
        }
        else
          this.backingNicType = ""

        this.maxInterfaceNumber = this.backing.max_interface_number || 9

        // should be last for watcher (except agent/headless)
        this.hardwareType = spec.hardware_type
        this.agent = spec.agent
        this.agentConfiguration = spec.agent_configuration ? spec.agent_configuration : {}
        if (["vmware_tools", "lade_vmware_agent"].includes(this.agent) && this.agentConfiguration.default_shell !== undefined) {
          this.agentDefaultShell = this.agentConfiguration.default_shell
        }
        if (this.agent === "vmware_tools" && this.agentConfiguration.tmp_dir !== undefined) {
          this.agentTmpDir = this.agentConfiguration.tmp_dir
        }

        this.headless = spec.headless

        this.attributes = spec.attributes
        this.preserveMac = this.attributes.preserve_mac
        if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
        if (this.$refs.hooks) this.$refs.hooks.$emit('reset', this.hooks)


        if ("image_source" in this.attributes) {
          for (let image of store.getters['files/getLabel'](this.iconsBucket)) {
            if (image.tag === this.attributes["image_source"]) {
              this.selectIcon(image)
            }
          }
        }
        this.$refs.addFormModal.show()
      })
    })

    this.$eventBus.$off('delete-host-spec-show')
    this.$eventBus.$on('delete-host-spec-show', ({topologyIdentifier, identifier}) => {
      let networkSpec = store.getters['topologies/getHostSpec'](topologyIdentifier, identifier)
      let topology = store.getters['topologies/get'](topologyIdentifier)
      this.$deleteModal({
        text: "Delete host " + networkSpec.name + " belonging to topology " + print_tag(false, false, topology) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('topologies/deleteHost', {topologyIdentifier, identifier}).then(() => {
            this.$eventBus.$emit('success', 'The host will be deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    })
  },
  data() {
    return {
      identifier: "",
      attributes: {},
      title: "",
      edit: false,
      hardwareTypeOptions: [
        {value: "VM_T", text: "Virtual machine"},
        {value: "CO_T", text: 'Container'},
        {value: "EM_T", text: "External machine (physical or virtual)"}
      ],
      bootOptions: [
        {value: "bios", text: "BIOS (Default)"},
        {value: "efi", text: 'EFI'},
      ],
      agentOptions: {
        "VM_T": [
          {value: "lade_agent", text: "LADE Agent"},
          {value: "lade_vmware_agent", text: 'LADE VMware Agent (Networkless)'},
          {value: "vmware_tools", text: "VMware Tools / Open VM Tools"},
          {value: null, text: "Not installed"},
        ],
        "EM_T": [
          {value: "lade_agent", text: "LADE Agent"},
          {value: null, text: "Not installed"},
        ],
        "CO_T": [
          {value: "container", text: "Container Agent"}
        ]
      },
      agentConfiguration: {},
      agentDefaultShell: "",  // vmware tools and lade vmware agent
      agentTmpDir: "",        // vmware tools configuration
      topologyIdentifier: "",
      hardwareType: "",
      hostname: "",
      username: "",
      password: "",
      os: null,
      agent: false,
      headless: true,
      immutable: false,
      priority: 100,
      ramMB: 2048,
      cpuCores: 2,
      maxInterfaceNumber: 9,
      diskSizeMB: 0,
      defaultGateway: "",
      backingNicType: "",
      nics: [],
      hooks: {},
      topology: {},
      showIcons: false,
      iconTag: null,
      iconWidth: 0,
      iconHeight: 0,
      bundleIdentifier: null,
      vid: "",
      backing: {},
      icon_updated: null,
      icon: {},
      iconTab: 0,
      lazyNetwork: true,
      hardwareVirtualization: false,
      preserveMac: false,
      image: null,
      firmware: "bios",
      netAdminCapability: false,
      environments: {}
    }
  },
  computed: {
    icons() {
      return store.getters['files/getLabel'](this.iconsBucket)
    },
    iconsBucket() {
      if (this.hardwareType === "EM_T") return "external-host-icon"
      return "host-icon"
    }
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = ""
      this.topologyIdentifier = ""
      this.agent = null
      this.bundleIdentifier = null
      this.ramMB = 2048
      this.cpuCores = 2
      this.maxInterfaceNumber = 9
      this.diskSizeMB = 0
      this.defaultGateway = ""
      this.hooks = {}
      this.topology = {}
      this.backing = {}
      this.attributes = {}
      this.nics = []
      this.priority = 100
      this.hardwareType = ""
      this.immutable = false
      this.hostname = ""
      this.username = ""
      this.password = ""
      this.showIcons = false
      this.iconTag = null
      this.iconWidth = 0
      this.iconHeight = 0
      this.os = null
      this.vid = ""
      this.iconTab = 0
      this.image = null
      this.icon = null
      this.hardwareVirtualization = false
      this.lazyNetwork = true
      this.preserveMac = false
      this.netAdminCapability = false
      this.environments = {}
    },
    loadModalResources() {
      let promises = []

      if (store.getters['files/getLabel']("host-icon") === undefined)
        promises.push(store.dispatch('files/loadLabel', {labels: "host-icon", load: true}))

      if (store.getters['files/getLabel']("external-host-icon") === undefined)
        promises.push(store.dispatch('files/loadLabel', {labels: "external-host-icon", load: true}))

      return Promise.all(promises)

    },
    onAddEdit() {

      let data = {
        "hardware_type": this.hardwareType,
        "hostname": this.hostname,
        "agent": this.agent,
        "agent_configuration": this.agentConfiguration,
        "headless": this.headless,
        "immutable": this.immutable,
        "priority": this.priority,
        "ram_mb": this.ramMB,
        "cpu_cores": this.cpuCores,
        "disk_size_mb": this.diskSizeMB,
        "nics": this.$refs.nicsForm.getValues(),
        "hooks": this.hooks,
        "attributes": {...this.attributes, ...{"preserve_mac": this.preserveMac}},
        "backing": {
          "hardware_virtualization": this.hardwareVirtualization,
          "lazy_network": this.lazyNetwork,
          "firmware": this.firmware,
          "max_interface_number": this.maxInterfaceNumber,
          "nic_backing": {},
        }
      }
      // Select existing
      if (this.iconTab === 0) {
        if (this.image != null) {

          data["icon"] = {}
          if ("width" in this.image.attributes)
            data["icon"]["width"] = this.image.attributes.width

          if ("height" in this.image.attributes)
            data["icon"]["height"] = this.image.attributes.height

          data["icon"]["data"] = this.image.data
        }

        //Upload new (iconTab == 1)
      } else {
        if (this.icon_updated != null)
          data["icon"] = this.icon_updated
      }

      if (this.defaultGateway !== "") {
        data["default_gateway"] = this.defaultGateway
      }
      if (this.backingNicType !== "") {
        data["backing"]["nic_backing"]["type"] = this.backingNicType
      }
      if (this.password !== "") {
        data["password"] = this.password
      }
      if (this.username !== "") {
        data["username"] = this.username
      }
      if (this.os !== "") {
        data["os"] = this.os
      }

      if (this.hardwareType === "EM_T") {
        data["vid"] = this.vid
      }

      if (this.hardwareType === "CO_T") {
        if (this.netAdminCapability) {
          data["backing"]["capabilities"] = ["NET_ADMIN"]
        }
        if (this.environments) {
          data["backing"]["environments"] = this.environments
        }
      }

      if (["vmware_tools", "lade_vmware_agent"].includes(this.agent) && this.agentDefaultShell) {
        this.agentConfiguration.default_shell = this.agentDefaultShell
      } else if (this.agentConfiguration.default_shell !== undefined) {
        delete(this.agentConfiguration.default_shell)
      }
      if (this.agent === "vmware_tools" && this.agentTmpDir) {
        this.agentConfiguration.tmp_dir = this.agentTmpDir
      } else if (this.agentConfiguration.tmp_dir !== undefined) {
        delete(this.agentConfiguration.tmp_dir)
      }

      if (this.edit) {
        store.dispatch('topologies/updateHost', {
          topologyIdentifier: this.topologyIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('topologies/addHost', {topologyIdentifier: this.topologyIdentifier, data: data}).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    selectIcon(image) {
      if (image == null) {
        this.iconTag = null
        return
      }
      this.iconTag = image.tag
      this.image = image
    }
  }
}

</script>

<style scoped>

.iconSelected {
  border-radius: 6px;
  border: 2px solid var(--color-grey);
}
</style>
