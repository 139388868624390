<template>
  <div>
    <b-row class="mx-md-5" style="position: absolute; z-index: 1">
      <b-col>
        <AirbusLogo />
      </b-col>
    </b-row>
    <header>
      <Header :isHeightScreen="true">
        <template #content>
          <b-container class="my-5">
            <b-row>
              <b-col class="d-flex justify-content-center">
                <div class="bg-green-brighter drop-shadow m-4 p-5 w-100">
                  <h2 class="text-black pb-4">Sign In</h2>

                  <div v-if="loading" class="d-flex justify-content-center">
                    <b-spinner class="m-5 mx-auto my-auto" />
                  </div>

                  <form v-else class="form-signin" @submit.prevent="onSubmit">
                    <b-input-group class="mt-4">
                      <label for="login-username" class="sr-only">
                        Username
                      </label>

                      <div class="input-group-prepend">
                        <div class="input-group-text" style="width: 35px">
                          <font-awesome-icon
                            @click="add()"
                            :icon="['fa', 'user']"
                          />
                        </div>
                      </div>

                      <b-input
                        type="text"
                        id="login-username"
                        v-model="username"
                        class="form-control"
                        placeholder="Username"
                        autocomplete="off"
                        required
                        autofocus
                      />
                    </b-input-group>

                    <b-input-group class="mt-4">
                      <label for="login-password" class="sr-only">
                        Password
                      </label>

                      <div class="input-group-prepend">
                        <div class="input-group-text" style="width: 35px">
                          <font-awesome-icon
                            @click="add()"
                            :icon="['fa', 'key']"
                          />
                        </div>
                      </div>

                      <b-input
                        ref="password"
                        type="password"
                        id="login-password"
                        v-model="password"
                        class="form-control"
                        placeholder="Password"
                        required
                      />
                    </b-input-group>

                    <b-button
                      class="btn btn-primary btn-block bg-dark mt-4"
                      type="submit"
                      style="color: var(--lade-color-white-hover) !important"
                    >
                      Sign In
                    </b-button>
                  </form>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </Header>
    </header>
  </div>
</template>

<script>
import store from "@/store";
import Config from "@/Config";
import Header from "@/components/hub/landing/Header";
import AirbusLogo from "@/components/hub/landing/AirbusLogo";

export default {
  name: "LoginView",
  components: { Header, AirbusLogo },
  created() {},
  mounted() {
    this.password = "";

    if (this.isTokenValid)
      // Already logged
      this.redirect();
    else if (this.$route.query.pat && this.$route.query.pat !== "") {
      // Login using PAT
      store
        .dispatch("token/issue", {
          token: this.$route.query.pat,
        })
        .then(() => {
          if (this.isTokenValid) this.redirect();
        })
        .catch((response) => {
          this.$eventBus.$emit("http-error", response);
          this.loading = false;
        });
    } else {
      this.$store
        .dispatch("plugins/runPublic", {
          labels: "login",
          store: this.$store,
          app: this,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((response) => {
          //this.$eventBus.$emit("http-error", response);
          this.loading = false;
        });
    }
  },
  data() {
    return {
      username: "",
      password: "",
      about: store.getters["about/get"](),
      loading: true,
    };
  },
  computed: {
    isTokenValid: () => store.getters["token/isValid"](),
  },
  methods: {
    redirect() {
      return store
        .dispatch("token/getTokenInfos")
        .then(() => {
          if (store.getters["token/isEula"]()) {
            this.$router.push({
              name: "eula",
              query: { rr: this.$route.query.rr, ar: this.$route.query.ar },
            });
          } else if (store.getters["token/isReset"]()) {
            this.$router.push({
              name: "reset",
              query: { rr: this.$route.query.rr, ar: this.$route.query.ar },
            });
          } else if (this.$route.query.rr) {
            this.$router.push(this.$route.query.rr);
          } else if (this.$route.query.ar) {
            let loc = this.$route.query.ar;
            let currentHost = document.location.host;
            let host = new URL(loc).host;
            if (host === currentHost) {
              document.location.replace(loc);
            }
          } else {
            document.location.replace(
              document.location.protocol +
                "//" +
                document.location.host +
                Config.base
            );
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.loading = true;

      let data = {
        username: this.username,
        password: this.password,
      };

      store
        .dispatch("token/issue", data)
        .then(() => {
          if (this.isTokenValid) {
            this.password = "";
            this.redirect();
          }
        })
        .catch((response) => {
          this.$eventBus.$emit("http-error", response);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
input[type="text"],
input[type="password"] {
  border-radius: 0 0 0 0;
  border: none;
}

input[type="submit"] {
  border-radius: 0px;
  border: none;
}

input:focus {
  box-shadow: none;
}

.input-group-text {
  border-radius: 0 0 0 0 !important;
  background-color: var(--lade-color-white-hover);
  border: none !important;
}

.input-group {
  margin-bottom: 8px;
}

#login-platform-name {
  margin-bottom: 20px;
}
</style>
